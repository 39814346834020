
























































































































































































































































































































import {Route} from 'vue-router'
import isEqual from 'lodash/isEqual'
import {cleanObject} from '@/helpers/query'
import {ProjectsService} from '@/services/projects'
import {searchTooltip} from '@/helpers/searchTooltipText'
import {Component, Vue} from 'vue-property-decorator'
import {OrganisationService} from '@/services/organisation'
import {mixin as clickaway} from '@/plugins/vue-clickaway'
import {AuthUserResource} from '@/models/users/AuthUserResource'
import {ImplementationsService} from '@/services/implementations'
import {documentsModule, organisationDocumentsModule, stateModule, userModule} from '@/store'

import TableRow from '@/components/table/Row.vue'
import TextInput from '@/components/inputs/Text.vue'
import DateInput from '@/components/inputs/Date.vue'
import NoItems from '@/components/partials/NoItems.vue'
import SearchInput from '@/components/inputs/Search.vue'
import SubHeader from '@/components/header/SubHeader.vue'
import TableWrapper from '@/components/table/Wrapper.vue'
import TableHeading from '@/components/table/Heading.vue'
import DefaultModal from '@/components/modals/Default.vue'
import PresetActions from '@/components/preset/Actions.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import Pagination from '@/components/paginations/Pagination.vue'
import CreatePresetModal from '@/components/preset/CreateModal.vue'
import ConfirmPresetUpdate from '@/components/preset/ConfirmUpdate.vue'
import FilterTooltip from '@/views/dashboard/projects/partials/FilterTooltip.vue'

import {DocumentPresetResource} from '@/models/documents/DocumentPresetResource'
import {OrganisationDocumentResource} from '@/models/organisationDocuments/OrganisationDocumentResource'
import {OrganisationDocumentPresetResource} from '@/models/organisationDocuments/OrganisationDocumentPresetResource'
import {OrganisationDocumentFilterResource} from '@/models/organisationDocuments/OrganisationDocumentFilterResource'
import {OrganisationDocumentCollectionResource} from '@/models/organisationDocuments/OrganisationDocumentCollectionResource'

import {ProjectCloneRequest} from '@/requests/projects/ProjectCloneRequest'
import {ImplementationCreateRequest} from '@/requests/implementations/ImplementationCreateRequest'
import {DocumentIndexRequest} from '@/requests/documents/DocumentIndexRequest'

const projectsService = new ProjectsService()
const implementationsService = new ImplementationsService()

@Component({
  mixins: [clickaway],
  components: {
    PresetActions,
    NoItems,
    TableRow,
    SubHeader,
    TextInput,
    DateInput,
    Pagination,
    SearchInput,
    DefaultModal,
    TableWrapper,
    TableHeading,
    DropdownInput,
    FilterTooltip,
    CreatePresetModal,
    ConfirmPresetUpdate
  },
})
export default class DocumentsOverview extends Vue {
  // Data
  private showFilters: boolean = false
  private showAllMinePresets: boolean = false
  private showAllPredefinedPresets: boolean = false
  private organisationService: OrganisationService = new OrganisationService()


  private countries: SelectItem[] = []
  private departments: SelectItem[] = []

  private searchTooltip: string = searchTooltip

  private cloneProjectModal: { isModalOpen: boolean, isFormSubmitting: boolean, form: ProjectCloneRequest | null, errors: ErrorResponse } = {
    errors: {},
    form: null,
    isModalOpen: false,
    isFormSubmitting: false
  }

  private isCreatePresetModalOpen: boolean = false
  private confirmPatchPresetModalOpen: boolean = false

  private implementationModal: ModalForm<ImplementationCreateRequest | null> = {
    isOpen: false,
    isSubmitting: false,
    form: null,
    errors: {}
  }

  // Computed
  private get showUpdatePresetButton(): boolean {
    let show: boolean = false
    const selectedPreset = this.selectedPreset
    if (this.filters && selectedPreset) {
      const allFilters = this.filters.map((filter: OrganisationDocumentFilterResource) => {
        const selectedPresetFilter = selectedPreset.values.find(({key}) => filter.key === key)
        return {
          presetValues: selectedPresetFilter ? selectedPresetFilter.values : filter.multiple ? [] : undefined,
          selectedValues: this.selectedFilters[filter.key],
        }
      })

      // If value of a preset if different than what is selected within a specific filter the preset is different and could be updated
      for (const filter of allFilters) {
        if (!isEqual(filter.presetValues, filter.selectedValues)) {
          show = true
        }
      }
    }
    return show
  }

  private get hasFilters(): boolean {
    return !!this.filters && this.filters.length > 0
  }

  private get user(): AuthUserResource | null {
    return userModule.user || null
  }

  private get loading(): boolean {
    return stateModule.loading
  }

  private get index(): IndexResponse<OrganisationDocumentCollectionResource> | null {
    return organisationDocumentsModule.index
  }

  private get userCanSelectDocument(): boolean {
    return userModule.canPerformAction('can_copy_project') || userModule.canPerformAction('can_create_implementation')
  }

  private get userCanCloneProject(): boolean {
    return userModule.canPerformAction('can_copy_project')
  }

  private get canCloneProject(): boolean {
    const DOCUMENT_CAN_COPY = this.selectedDocument ? this.selectedDocument.status !== 'being-updated' && this.selectedDocument.status !== 'inactive' : false
    return this.userCanCloneProject && DOCUMENT_CAN_COPY
  }

  private get query(): DocumentIndexRequest & IndexParameters {
    return organisationDocumentsModule.query
  }

  private get selectedFilters(): DocumentIndexRequest {
    return organisationDocumentsModule.selectedFilters
  }

  private get selectedFiltersObject(): Array<OrganisationDocumentFilterResource & { value: string | Array<string | null> }> {
    const FILTER_TYPES: Array<OrganisationDocumentFilterResource & { value: string | Array<string | null> }> = []
    const filters = this.filters
    if (filters) {
      Object.keys(this.selectedFilters).forEach((key) => {
        // If selected filter is an value && (value is typeof string) OR (value is typeof OBJECT check if array is filled)
        const selectedFilter = this.selectedFilters[key]
        if (selectedFilter && (typeof selectedFilter === 'string' || (typeof selectedFilter === 'object' && selectedFilter.length > 0))) {
          const filter = filters.find((f) => f.key === key)
          if (filter) {
            FILTER_TYPES.push({
              value: selectedFilter,
              ...filter,
            })
          }
        }
      })
    }
    return FILTER_TYPES
  }

  private get projectCloneComplete(): boolean {
    return !!this.cloneProjectModal.form && this.cloneProjectModal.form.project_name.length > 0
  }

  private get hasFiltersSelected(): boolean {
    return this.selectedFiltersObject.length > 0
  }

  private canFilterSearch(filter: OrganisationDocumentFilterResource): boolean {
    return ['type', 'issuer', 'subject', 'topic', 'country', 'project_ids'].includes(filter.key)

  }

  private get filters(): OrganisationDocumentFilterResource[] | null {
    return organisationDocumentsModule.filters
  }

  private get bookmarked(): boolean {
    return organisationDocumentsModule.bookmarked
  }

  private get presets(): OrganisationDocumentPresetResource[] | null {
    return organisationDocumentsModule.presets
  }

  private get selectedDocument(): OrganisationDocumentCollectionResource | null {
    return organisationDocumentsModule.selectedDocument
  }

  private get selectedPreset(): OrganisationDocumentPresetResource | undefined {
    return this.presets
        ? this.presets.find((preset: OrganisationDocumentPresetResource) => preset.id === organisationDocumentsModule.selectedPreset)
        : undefined
  }

  private get filteredPresets(): {[key: string]: OrganisationDocumentPresetResource[]} | null {
    if (this.presets) {
      return {
        mine: this.showAllMinePresets
            ? this.presets.filter((preset) => !preset.predefined)
            : this.presets.filter((preset) => !preset.predefined).splice(0, 5),
        predefined: this.showAllPredefinedPresets
            ? this.presets.filter((preset) => preset.predefined)
            : this.presets.filter((preset) => preset.predefined).splice(0, 5),
      }
    }
    return null
  }

  private get hasDocuments(): boolean {
    return !!this.index && this.index.data.length > 0
  }

  private get hasDocumentOpen(): boolean {
    return !!this.$route.params.document_id
  }

  private get canCreateClonedProject(): boolean {
    return this.selectedDocument ? this.userCanCloneProject : false
  }

  private get canCreateImplementation(): boolean {
    return this.selectedDocument ? this.selectedDocument.canPerformAction('can_create_implementation') : false
  }

  // Lifecycle hooks
  private async beforeRouteEnter(to: Route, from: Route, next: any): Promise<void> {
    stateModule.setLoading(true)
    try {
      await Promise.all([
        organisationDocumentsModule.get(to.query as DocumentIndexRequest),
        organisationDocumentsModule.getFilters(to.query as DocumentIndexRequest),
        organisationDocumentsModule.getPresets(),
      ])
      organisationDocumentsModule.setPreset()

      // Set preset if coming from query
      if (to.query.presets) {
        organisationDocumentsModule.setPreset(parseInt(to.query.presets.toString(), 10))
      }

      next()
    } catch ({message}) {
      next(from.path || {name: 'dashboard'})
    } finally {
      stateModule.setLoading(false)
    }
  }

  private created(): void {
    this.getOptions()
  }

  private beforeDestroy(): void {
    this.clearFilters()
    this.resetSelectedDocument()
    this.setParam('search', '')
    organisationDocumentsModule.setParam({key: 'search', value: ''})
  }

  private destroyed(): void {
    organisationDocumentsModule.removeIndex()
  }

  // computed methods
  private isDraggable(document: OrganisationDocumentCollectionResource): boolean {
    return this.$route.params.document_id ? parseInt(this.$route.params.document_id, 10) !== document.id : false
  }

  private isActivePreset(id: number): boolean {
    return this.selectedPreset ? this.selectedPreset.id === id : false
  }

  private getComponent(type: string): string {
    if (type === 'dropdown') {
      return 'DropdownInput'
    } else if (type === 'date') {
      return 'DateInput'
    } else {
      return 'TextInput'
    }
  }

  private isSelected(document: OrganisationDocumentCollectionResource): boolean {
    return this.selectedDocument?.id === document.id
  }

  // Methods
  private hasMorePresets(key: string): boolean {
    if (this.presets && key === 'predefined') {
      return this.presets.filter((preset) => preset.predefined).length > 5
    } else if (this.presets) {
      return this.presets.filter((preset) => !preset.predefined).length > 5
    }
    return false
  }

  private isPresetOpen(key: string): boolean {
    if (key === 'predefined') {
      return this.showAllPredefinedPresets
    } else {
      return this.showAllMinePresets
    }
  }

  private togglePresets(key: string): void {
    if (key === 'predefined') {
      this.showAllPredefinedPresets = !this.showAllPredefinedPresets
    } else {
      this.showAllMinePresets = !this.showAllMinePresets
    }
  }

  private deletePreset(preset: DocumentPresetResource) {
    documentsModule.deletePreset({preset})
    documentsModule.setPreset()
  }

  private patchPreset() {
    documentsModule.getPresets()
    this.user?.getPresets()
  }


  private updateSelectedDocument(document: OrganisationDocumentCollectionResource): void {
    organisationDocumentsModule.selectDocument(document)
  }

  private resetSelectedDocument(): void {
    organisationDocumentsModule.resetSelectedDocument()
  }

  private toggleFilters(): void {
    this.showFilters = !this.showFilters
  }

  private clearFilters(): void {
    organisationDocumentsModule.setPreset()
    organisationDocumentsModule.resetFilter()
    organisationDocumentsModule.setParam({key: 'page', value: 1})
    this.$nextTick(() => {
      this.submit()
    })
  }

  private getRowElements(document: OrganisationDocumentCollectionResource): TableDataElement[] {
    const data: TableDataElement[] = []
    if (this.index && this.index.elements) {
      this.index.elements.forEach((element: TableElement) => {
        const KEY = element.key as keyof OrganisationDocumentCollectionResource
        const ELEMENT_VALUE = document[KEY]
        let element_value: OrganisationDocumentCollectionResource[keyof OrganisationDocumentCollectionResource] = '-'
        if (typeof ELEMENT_VALUE === 'string' || typeof ELEMENT_VALUE === 'number' || typeof ELEMENT_VALUE === 'boolean') {
          element_value = ELEMENT_VALUE
        } else if (Array.isArray(ELEMENT_VALUE)) {
          element_value = ELEMENT_VALUE.length > 0 ? ELEMENT_VALUE.join(', ') : '-'
        }
        data.push({
          value: element_value,
          type: element.type,
          width: element.width,
        })
      })
    }
    return data
  }

  private setOrder(col: string, dir: string): void {
    organisationDocumentsModule.setOrder({col, dir})
    this.submit()
  }

  private setFilter(key: string, value: string): void {
    organisationDocumentsModule.setFilter({key, value})
    // Always set back to first page after applying a filter
    organisationDocumentsModule.setParam({key: 'page', value: 1})
  }

  private setParamAndSubmit(key: string, value: string): void {
    organisationDocumentsModule.setFilter({key, value})
    if (key !== 'page') {
      // Always set back to first page after applying a filter
      organisationDocumentsModule.setParam({key: 'page', value: 1})
    }
    this.$nextTick(() => {
      this.submit()
    })
  }

  private setParam(key: string, value: string): void {
    organisationDocumentsModule.setFilter({key, value})
    if (key !== 'page') {
      // Always set back to first page after applying a filter
      organisationDocumentsModule.setParam({key: 'page', value: 1})
    }
  }

  private setPreset(id: number): void {
    organisationDocumentsModule.resetFilter()
    organisationDocumentsModule.setPreset(id)
    // Always set back to first page after applying a filter
    organisationDocumentsModule.setParam({key: 'page', value: 1})
    this.$nextTick(() => {
      this.submit()
    })
  }

  private switchBookmark(value: boolean): void {
    organisationDocumentsModule.setParam({key: 'page', value: 1})
    organisationDocumentsModule.setPreset()
    organisationDocumentsModule.resetFilter()

    organisationDocumentsModule.setBookmarked(value)
    this.$nextTick(() => {
      this.setParamAndSubmit('search', '')
    })
  }

  private goToPage(val: string): void {
    organisationDocumentsModule.setParam({key: 'page', value: val})
    this.$nextTick(() => {
      this.submit()
    })
  }

  private async submit(): Promise<void> {
    stateModule.setLoading(true)
    try {
      await organisationDocumentsModule.get()
      this.setQuery()
    } finally {
      stateModule.setLoading(false)
    }
  }

  private setQuery(): void {
    const query = cleanObject({
      ...this.query,
      presets: this.selectedPreset ? this.selectedPreset.id : undefined,
    })
    if (this.$route.params.documetId) {
      this.$router.replace({
        name: this.$route.name || '',
        params: {document_id: this.$route.params.documetId},
        query,
      })
    } else {
      this.$router.replace({name: this.$route.name || '', query})
    }
  }

  private togglePostPresetModel(): void {
    this.isCreatePresetModalOpen = !this.isCreatePresetModalOpen
  }

  private closeCreatePresetModal() {
    this.isCreatePresetModalOpen = false
  }

  private presetCreated(preset: DocumentPresetResource) {
    this.setPreset(preset.id)
    this.user?.getPresets()
    organisationDocumentsModule.getPresets()
  }

  private closeConfirmPatchPresetModal() {
    this.confirmPatchPresetModalOpen = false
  }

  private confirmPatchPreset() {
    this.confirmPatchPresetModalOpen = true
  }

  private async patchPresetFilters({message}: { message: string }): Promise<void> {
    this.closeConfirmPatchPresetModal()
    if (this.selectedPreset) {
      stateModule.setLoading(true)
      try {
        const filters = cleanObject({...this.selectedFilters})
        await this.selectedPreset.patch({filters, message})
        await this.submit()
        stateModule.setNotification({
          message: `Preset '${this.selectedPreset?.name}' has been updated.`,
          type: 'success',
        })
      } catch ({errors}) {
        console.error(errors)
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private async getOptions(): Promise<void> {
    try {
      const [countries, departments] = await Promise.all([this.organisationService.getCountries(), this.organisationService.getDepartments()])
      this.countries = Object.keys(countries).map((key: string) => ({
        value: key,
        label: countries[key],
      }))
      this.departments = departments.map(({id, name}) => ({
        value: id,
        label: name,
      }))
    } catch (e) {
      console.error(e)
    }
  }

  private getPresetTooltip(type: string): string {
    switch (type) {
      case 'mine':
        return '<strong>My presets</strong>You can quickly repeat a search by saving your search criteria as a preset.<br><br>You will be notified of newly added documents in the library that match your presets'
      case 'predefined':
        return '<strong>Predefined presets</strong>These are presets defined by your organisation. You can not edit these.'
      default:
        return ''
    }
  }

  private openCloneProjectModal(type: 'clone' | 'reference'): void {
    if (this.selectedDocument) {
      this.cloneProjectModal.form = new ProjectCloneRequest({project_id: this.selectedDocument.project_info.id, type})
      this.cloneProjectModal.isModalOpen = true
    } else {
      this.cloneProjectModal.isModalOpen = false
    }
  }

  private closeCloneProjectModal(): void {
    this.cloneProjectModal.isModalOpen = false
    this.cloneProjectModal.form = null
  }

  private async cloneProject(): Promise<void> {
    if (this.cloneProjectModal.form) {
      this.cloneProjectModal.isFormSubmitting = true
      try {
        const {data} = await projectsService.clone(this.cloneProjectModal.form)
        this.closeCloneProjectModal()
        this.$router.push({name: 'projects-overview'})
      } catch ({errors}) {
        this.cloneProjectModal.errors = errors
      } finally {
        this.cloneProjectModal.isFormSubmitting = false
      }
    }
  }

  private openImplementationModal(): void {
    if (!this.selectedDocument) return
    this.implementationModal.form = new ImplementationCreateRequest(this.selectedDocument)
    this.implementationModal.isOpen = true
  }

  private closeImplementationModal(): void {
    if (!this.selectedDocument) return
    this.implementationModal.isOpen = false
    // Wait for animation to close modal before resetting form otherwise they will see that the form is being reset
    setTimeout(() => {
      this.implementationModal.form = null
      this.implementationModal.errors = {}
    }, 300)
  }

  private async createImplementation(): Promise<void> {
    if (!this.implementationModal.form) return

    const form = {...this.implementationModal.form }
    form.template_id = this.selectedDocument ? this.selectedDocument.id : null

    this.implementationModal.isSubmitting = true
    this.implementationModal.errors = {}

    try {
      const data = await implementationsService.create(form)
      await this.$router.push({name: 'implementation-dashboard', params: {implementation_id: `${data.data.id}`}})
    } catch (e) {
      if (e.errors) {
        this.implementationModal.errors = e.errors
      }
    } finally {
      this.implementationModal.isSubmitting = false
    }
  }

}
